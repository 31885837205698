<template>
    <div class="row px-2">
        <div class="xs12">
            <DxTextBox
                v-model="textTimKiem"
                placeholder="Tìm kiếm"
                styling-mode="outlined"
                height="36px"
                :showClearButton="true"
                :elementAttr="{ class: 'custom-editor-search' }"
            />
        </div>
    </div>

    <div class="row px-3 font-italic font-12 mt-1 mb-2">
        Nhập Điểm xuống, SĐT, Tên người mua, Trạng thái để tìm kiếm! (Vd: Đã lên xe, Chưa
        lên xe, ...)
    </div>
    <DxList
        :dataSource="{
            store: DanhSachVeKhongSoDo,
            paginate: true,
            requireTotalCount: true,
            pageSize: 10,
            filter: dataFilter,
        }"
        page-load-mode="nextButton"
        height="calc(100vh - 240px)"
        item-template="list-item"
        :hoverStateEnabled="false"
        :focusStateEnabled="false"
        :activeStateEnabled="false"
    >
        <template #list-item="{ data, index }">
            <div>
                <div class="row card-ticket mr-2">
                    <div class="grow">
                        <div :class="rowText">
                            <div>Số điện thoại:</div>
                            <div class="font-medium color-success">
                                {{ "\xa0" + data.soDienThoai }}
                            </div>
                        </div>
                        <div :class="rowText">
                            <div>Giá vé:</div>
                            <div class="font-medium color-error">
                                {{ "\xa0" + $MoneyFormat(data.giaVe) }}đ
                            </div>
                        </div>
                        <div
                            :class="rowText"
                            v-if="
                                ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                    $t(
                                        'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                    )
                                ] == 'true'
                            "
                        >
                            <div>Giá vé sau giảm trừ:</div>
                            <div class="font-medium color-warning">
                                {{ "\xa0" + $MoneyFormat(data.thanhTien) }}đ
                            </div>
                        </div>
                        <div :class="rowText">
                            <div>Điểm xuống:</div>
                            <div class="font-medium color-primary">
                                {{ "\xa0" + data.diemXuong }}
                            </div>
                        </div>
                        <div class="row">
                            <div>Trạng thái:</div>
                            <div
                                class="font-medium"
                                :style="`color: ${data.maMauTrangThaiHanhKhach}`"
                            >
                                {{ "\xa0" + data.tenTrangThaiHanhKhach }}
                            </div>
                        </div>
                    </div>
                    <div class="shrink">
                        <div class="row align-center fill-height">
                            <div class="xs12">
                                <div class="row justify-center">
                                    <DxButton
                                        icon="mdi mdi-printer"
                                        type="default"
                                        styling-mode="text"
                                        @click="InLaiVe(data)"
                                    />
                                    <DxButton
                                        v-if="
                                            data.idTrangThaiHanhKhach ==
                                            $t('TrangThaiKhachGhePhu.DaLenXe')
                                        "
                                        icon="mdi mdi-transfer-down"
                                        type="success"
                                        styling-mode="text"
                                        @click="XacNhanKhachXuongXe(data.maDatCho)"
                                    />
                                    <DxButton
                                        v-if="
                                            data.idTrangThaiHanhKhach ==
                                            $t('TrangThaiKhachGhePhu.ChuaLenXe')
                                        "
                                        icon="mdi mdi-transfer-up"
                                        type="default"
                                        styling-mode="text"
                                        @click="XacNhanKhachLenXe(data)"
                                    />
                                </div>
                                <div class="row justify-center mt-1">
                                    <DxButton
                                        v-if="
                                            data.idTrangThaiHanhKhach ==
                                            $t('TrangThaiKhachGhePhu.ChuaLenXe')
                                        "
                                        icon="mdi mdi-account-off-outline"
                                        type="danger"
                                        styling-mode="text"
                                        @click="XacNhanKhachLoChuyen(data)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DxList>
    <!-- <div class="xs12 card-ticket">
            <div class="row pa-3 pb-2">
                <div class="xs12">
                    <div :class="rowText">
                        <div class="xs5">Số điện thoại:</div>
                        <div :class="textRight" class="color-success">
                            {{ $Helper.formatPhone(item.soDienThoai) }}
                        </div>
                    </div>
                    <div :class="rowText">
                        <div class="xs5">Giá vé:</div>
                        <div :class="textRight" class="color-error">
                            {{ $MoneyFormat(item.giaVe) }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="xs5">Điểm xuống:</div>
                        <div :class="textRight" class="color-warning">
                            {{ item.diemXuong }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="border-top: 1px solid #dadce0; padding: 0 2px">
                <div class="xs6 text-xs-center" style="border-right: 1px solid #dadce0">
                    <DxButton
                        text="In vé"
                        type="default"
                        styling-mode="text"
                        width="100%"
                        @click="InLaiVe(item)"
                    />
                </div>
                <div class="xs6 text-xs-center">
                    <DxButton
                        text="Xuống xe"
                        type="default"
                        styling-mode="text"
                        width="100%"
                        @click="XuongXe(item.maDatCho)"
                    />
                </div>
            </div>
        </div> -->
</template>

<script>
import { DxButton, DxList, DxTextBox } from "devextreme-vue";
import ChuyenDi from "../../class/ChuyenDi";
import DonHang from "../../class/DonHang";
import { mapGetters, mapState } from "vuex";
export default {
    components: { DxButton, DxList, DxTextBox },
    props: {
        guidChuyenDi: { type: String, default: null },
    },
    data() {
        return {
            rowText: "row mb-1",
            textRight: "xs7 font-medium",
            DanhSachVeKhongSoDo: [],
            textTimKiem: null,
        };
    },
    computed: {
        ...mapState({
            ToaDo: (state) => state.ThongTin.ToaDo,
        }),
        ...mapGetters("Ve", ["chuyenDiGanDay"]),
        dataFilter() {
            // let trangThai =
            //     this.filter.TrangThai && this.filter.TrangThai != 0
            //         ? ["idTrangThai", "=", parseInt(this.filter.TrangThai)]
            //         : null;
            let timKiem = this.textTimKiem
                ? [
                      ["diemXuong", "contains", this.textTimKiem],
                      "or",
                      ["hoTen", "contains", this.textTimKiem],
                      "or",
                      ["tenTrangThaiHanhKhach", "contains", this.textTimKiem],
                      "or",
                      ["soDienThoai", "contains", this.textTimKiem],
                  ]
                : null;
            let filter = timKiem || null;
            return filter;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    watch: {
        guidChuyenDi: {
            immediate: true,
            handler: function () {
                if (this.guidChuyenDi) {
                    this.layDanhSachVeKhongSoDo();
                }
            },
        },
    },
    methods: {
        InLaiVe(ThongTinKhachHang) {
            if (ThongTinKhachHang.daPhatHanh == false) {
                let query = {
                    guidChuyenDi: this.chuyenDiGanDay.guidChuyenDi,
                    maDatCho: ThongTinKhachHang.maDatCho,
                };
                this.PhatHanhHoaDon(query, ThongTinKhachHang.maDatCho);
            } else {
                this.layThongTinVe(ThongTinKhachHang.maDatCho);
            }
        },
        async PhatHanhHoaDon(query, maDatCho) {
            try {
                let donHang = new DonHang();
                let rs = await donHang.phatHanhVeTheoMaDatCho(query);
                if (!rs.Data.status) {
                    return this.$emit("thongBaoLoi", rs.Data.message);
                }
            } catch (error) {
                this.$emit("thongBaoLoi", "", error);
            }
            this.layThongTinVe(maDatCho);
            this.layDanhSachVeKhongSoDo();
        },
        async layThongTinVe(maGiaoDich) {
            try {
                let rs = await this.$AppClass.InVe(
                    this.$i18n.t("url.InVeTheoMaDatCho"),
                    "maDatCho",
                    maGiaoDich,
                );
                if (rs == "error") {
                    return this.$emit("thongBaoLoi", "error", "In thất bại! ");
                }
                this.$stopLoading;
            } catch (error) {
                this.$stopLoading;
                this.error = true;
                console.error(error);
            }
        },
        async XacNhanKhachXuongXe(maDatCho) {
            try {
                let chuyenDi = new ChuyenDi();
                chuyenDi.guidXe = this.chuyenDiGanDay.guidXe;
                chuyenDi.maChuyenDi = this.chuyenDiGanDay.maChuyenDi;
                chuyenDi.guidChuyenDi = this.chuyenDiGanDay.guidChuyenDi;
                let ketQua = await chuyenDi.khongSoDoCho_XacNhanKhachXuongXe(
                    maDatCho,
                    this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                );
                if (!ketQua.Data.status) {
                    return this.$emit("thongBaoLoi", ketQua.Data.message);
                }
                this.$Helper.ThongBaoToast(
                    "success",
                    "Xác nhận hành khách xuống xe thành công!",
                );
                this.layDanhSachVeKhongSoDo();
            } catch (error) {
                this.$emit("thongBaoLoi", "", error);
            }
        },
        async XacNhanKhachLenXe(data) {
            try {
                let query = {
                    maChuyenDi: this.chuyenDiGanDay.maChuyenDi,
                    maDatCho: data.maDatCho,
                    guidXe: this.chuyenDiGanDay.guidXe,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanKhachLenXe",
                    ServicesBE: "DoiSoat",
                    url: this.$t("url.SoatVe"),
                    dataPost: query,
                    msgSuccess: "Xác nhận hành khách lên xe thành công!",
                    msgError: "Xác nhận hành khách lên xe thất bại!",
                    msgCatch: "Xác nhận hành khách lên xe thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                this.layDanhSachVeKhongSoDo();
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
        async XacNhanKhachLoChuyen(data) {
            try {
                let query = {
                    maChuyenDi: this.chuyenDiGanDay.maChuyenDi,
                    maDatCho: data.maDatCho,
                    guidXe: this.chuyenDiGanDay.guidXe,
                    toaDo: this.ToaDo ? JSON.stringify(this.ToaDo) : "",
                };
                let rs = await this.$Events.RequestPost({
                    keyRequest: "XacNhanKhachLoChuyen",
                    ServicesBE: "DoiSoat",
                    url: this.$t("url.XacNhanKhachLoChuyen"),
                    dataPost: query,
                    msgSuccess: "Xác nhận hành khách lỡ chuyến thành công!",
                    msgError: "Xác nhận hành khách lỡ chuyến thất bại!",
                    msgCatch: "Xác nhận hành khách lỡ chuyến thất bại!",
                    typeNotificationSuccess: "Toast",
                    typeNotificationError: "Toast",
                });
                this.layDanhSachVeKhongSoDo();
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
        async layDanhSachVeKhongSoDo() {
            try {
                this.DanhSachVeKhongSoDo = (
                    await this.$store.dispatch(
                        "NhanVienBanVe/LayDanhSachKhachBanVeKhongSoDo",
                        this.guidChuyenDi,
                    )
                ).Store;
            } catch (error) {
                this.$emit("thongBaoLoi", error);
            }
        },
    },
    created() {},
};
</script>

<style scoped>
.card-ticket {
    box-shadow: 0px 2px 9px #ccc;
    padding: 16px;
}
.custom-editor-search :deep(.dx-placeholder) {
    display: flex;
    align-items: center;
}
</style>
