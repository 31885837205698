<template>
    <div class="row">
        <div class="xs12 font-16 font-medium text-xs-center">
            Vé không hợp lệ! Đây không phải vé dành cho chuyến đi này.
        </div>
    </div>
    <div class="row mt-3 justify-space-between">
        <div class="xs6">Tuyến vận chuyển</div>
        <div class="xs6 font-medium">
            {{
                `${ThongTinSoatVeChuyenDiTruoc.tenLuongTuyen} (${ThongTinSoatVeChuyenDiTruoc.maLuongTuyen})`
            }}
        </div>
    </div>
    <div class="row mt-3 justify-space-between">
        <div class="xs6">Biển kiểm soát</div>
        <div class="xs6 font-medium">
            {{ ThongTinSoatVeChuyenDiTruoc.bienKiemSoat }}
        </div>
    </div>
    <div class="row mt-3 justify-space-between">
        <div class="xs6">Giờ XB</div>
        <div class="xs6 font-medium">
            {{
                $Helper.Common.formatDateTime(
                    ThongTinSoatVeChuyenDiTruoc.thoiGianXuatBenDuKien,
                )
            }}
        </div>
    </div>
    <div class="row mt-3 justify-space-between">
        <div class="xs6">Số điện thoại lái xe</div>
        <div class="xs6 font-medium">
            {{ ThongTinSoatVeChuyenDiTruoc.soDienThoai }}
        </div>
    </div>
    <div class="row mt-3 justify-space-between">
        <div class="xs6">Vị trí đỗ xe trong bến</div>
        <div class="xs6 font-medium">
            {{ ThongTinSoatVeChuyenDiTruoc.viTriDoXe }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ThongTinSoatVeChuyenDiTruoc: {
            type: Object,
            default: {
                bienKiemSoat: null,
                giaVe: null,
                guidChuyenDi: null,
                guidXe: null,
                logoUrl: null,
                maChuyenDi: null,
                maLuongTuyen: null,
                noiDen: null,
                noiDi: null,
                soDienThoai: null,
                tenDoanhNghiep: null,
                tenLuongTuyen: null,
                thoiGianKhoiHanh: null,
                thoiGianXuatBenDuKien: null,
                viTriDoXe: null,
            },
        },
    },
};
</script>

<style scoped></style>
